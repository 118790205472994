import styled from 'styled-components'

const HomeStyle = styled.div`
    background-color : #102832;
    height:100vh;
    width:100vw;
    color : black;
    font-family: 'Roboto', sans-serif;
    display:flex;
    flex-direction:column;
`
export default HomeStyle