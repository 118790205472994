import React from 'react'
import Header from '../Components/Header'
import Dashboard from './Dashboard'
import HomeStyle from './styles/Home.styled'



function Home(props) {
    const auth = props.auth
    return (
        <HomeStyle>
            <Header auth={auth}></Header>
            <Dashboard  access_token={props.auth.user.access_token} user={auth.user?.profile.email} applicationConfig={props.applicationConfig}></Dashboard>
        </HomeStyle>
    )
}

export default Home
